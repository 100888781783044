import { combineReducers } from "redux";
import {
  items,
  itemsHaveError,
  itemsAreLoading,
  bcfData,
  toastNotificationContent,
} from "./bcfData";
import { issues } from "./issues";
import { zoomLevel } from "./sample";
import {
  fileVersions,
  filesSharedUnderResources,
  setFilesData,
  setFileDependencies,
  currentFileVersionId,
} from "./fileVersion";
import {
  refreshFileManager,
  selectedFiles,
  currentFolder,
  fileManagerBreadCrumb,
} from "./refreshFileManager";
import {
  projectData,
  spaceData,
  allSpacesInProject,
  breadCrumbData,
} from "./breadcrumbsdata";
import { issuesStatuses } from "./issuesStatuses";
import { tasksStatuses } from "./tasksStatuses";
import { save_space_List } from "./dashboard";
import { deleteIssueData } from "./deleteIssues";
import { editIssues } from "./editIssues";
import { currentPath, filesStatuses } from "./filesStatuses";
import { estimateStatuses } from "./estimateStatuses";
import { projectsStatuses } from "./projectsStatuses";
import {
  saveTransmital,
  saveTransmitalMetadata,
  setTransmittalAccess,
  setTransmittalVersions,
} from "./transmitals";
import { userDetails } from "./userDetails";
import { userPermissions, portalSettingInfo } from "./userPermissions";
import {
  roleData,
  spacesUsersData,
  setEntityList,
  statusData,
  setCategoryList,
  deleteSpace,
  DeletedUserState,
  getProjectSettingObject,
} from "./projectSetting";
import { projectUsers, spaceUsers, UserInProject } from "./projectUsers";
import {
  TaskData,
  TaskDetails,
  GanttDetails,
  TaskTypes,
  sliderTimer,
  selectionSetTaskElements,
} from "./Tasks";
import { resourceTypes, resources, resourceCreated } from "./Resources";
import { contractDetails } from "./Contracts.js";
import { currencyDetails } from "./currency.js";
import { clashDetails, collideeData, colliderData } from "./clash";
import { isFileAnOlderVersion } from "./designViewer";
import {
  fileUploadList,
  fileUploadState,
  isFileVersionUploading,
} from "./minimiseFileUpload";

import {
  UserInPortal,
  currentUserPermissions,
  statusPortalData,
  portalEntityList,
  setPortalCategoryList,
  getDsrData,
  getSymbolsData,
  profileData,
  getPortalSetting,
  portalData,
} from "./portalSetting";
import {
  notificationsData,
  notificationsStatus,
  unreadNotificationsCount,
  showNotification,
} from "./notification";
import {
  HoopsModalStatus,
  GetSettingModalData,
  nodeIdAndUniqueIdMap,
  transparentElements,
  getSelectedNestedMeasurements,
  measurementAttributePopup,
  isBottomToolbarRefreshRequired,
  viewerState,
  isSheetActive,
  faceSelection,
  modelProperties,
} from "./HoopsViewer";
import { searchData } from "./GlobalSearchReducer.js";
import {
  spaceUserDataForFolder,
  setFolderRoles,
  setFolderData,
  getAccessPermission,
  getSpaceToggle,
} from "./AccessPermissionReducer";
import { licenceStatus, privacyPolicyStatus } from "./licence";
import { companyDetails } from "./companyDetails";
import { issueCount } from "./issuesCount";
import { getSpaceRoles } from "./spaceSetting";
import {
  getEstimateList,
  getEstimateAdditionalCost,
  getDateVersion,
  estimateData,
  additionalCostTotal,
  estimateFieldsNeedToRefresh,
} from "./EstimateList";
import { getDsrItems, costDatabase } from "./DsrReducer";
import {
  measurementsList,
  takeoffsList,
  takeoffData,
  measurementsModalStatus,
  isMeasurementStarted,
} from "./measurements";
import {
  selectedSet,
  selectionList,
  selectionListIdObj,
  selectedSelectionSetItemsList,
} from "./selections";
import { layout } from "./layout";
import { minimizeFloatterData } from "./minimizerFloatter";
import { UserCountInPortal } from "./portalSetting";
import { portalDashboardData, portalDashboardObject } from "./portalDashboard";
import { projectDashboardData } from "./projectDashboard";
import { issuesPinData } from "./issuesPinData";
import { extraIssue } from "./extraIssue";
import { commonObjectData, fileversionIdAndNodeOffsetIdMap } from "./common";
import { orgStructureData, projectAttachedPosition } from "./orgStructure";
import { workflowData, workflowResourceType, reviewData } from "./Workflows";
import { Databank } from "./Databank";
import {
  currentSelectedSidebarCell,
  showStickySidebar,
} from "./stickySidebar.js";
import browserPopupReducer from "./browserPopupReducer.js";
import { userTagData } from "./userTagData.jsx";
import { fileSyncState } from "./fileSync.js";

export default combineReducers({
  userTagData,
  items,
  getSymbolsData,
  itemsHaveError,
  itemsAreLoading,
  bcfData,
  issues,
  fileVersions,
  refreshFileManager,
  projectData,
  spaceData,
  editIssues,
  issuesStatuses,
  tasksStatuses,
  save_space_List,
  deleteIssueData,
  selectedFiles,
  currentFolder,
  filesStatuses,
  projectsStatuses,
  transmittals: saveTransmital,
  transmittalMetadata: saveTransmitalMetadata,
  transmittalVersions: setTransmittalVersions,
  transmittalAccess: setTransmittalAccess,
  userDetails,
  projectUsers,
  spaceUsers,
  searchData,
  roleData,
  spacesUsersData,
  spaceUserDataForFolder,
  setFolderData,
  setFolderRoles,
  TaskData,
  TaskDetails,
  TaskTypes,
  GanttDetails,
  clashDetails,
  sliderTimer,
  selectionSetTaskElements,
  resourceTypes,
  resources,
  setEntityList,
  statusData,
  setCategoryList,
  fileManagerBreadCrumb,
  filesSharedUnderResources,
  filesData: setFilesData,
  currentFileVersionId,
  UserInProject,
  allSpacesInProject,
  toastNotificationContent,
  UserInPortal,
  notificationsData,
  notificationsStatus,
  deleteSpace,
  DeletedUserState,
  currentUserPermissions,
  statusPortalData,
  setPortalCategoryList,
  portalEntityList,
  fileDependencies: setFileDependencies,
  HoopsModalStatus,
  licenceStatus,
  privacyPolicyStatus,
  userPermissions,
  unreadNotificationsCount,
  showNotification,
  companyDetails,
  GetSettingModalData,
  issueCount,
  getSpaceRoles,
  getEstimateList,
  getDsrData,
  getDsrItems,
  measurementsList,
  takeoffsList,
  takeoffData,
  measurementsModalStatus,
  isMeasurementStarted,
  getEstimateAdditionalCost,
  layout,
  getAccessPermission,
  getSpaceToggle,
  zoomLevel,
  profileData,
  getPortalSetting,
  portalSettingInfo,
  getSelectedNestedMeasurements,
  measurementAttributePopup,
  getProjectSettingObject,
  selectedSet,
  selectionList,
  selectionListIdObj,
  selectedSelectionSetItemsList,
  minimizeFloatterData,
  UserCountInPortal,
  portalDashboardData,
  portalDashboardObject,
  issuesPinData,
  extraIssue,
  commonObjectData,
  portalData,
  getDateVersion,
  estimateStatuses,
  projectDashboardData,
  transparentElements,
  costDatabase,
  orgStructureData,
  projectAttachedPosition,
  nodeIdAndUniqueIdMap,
  isBottomToolbarRefreshRequired,
  isSheetActive,
  faceSelection,
  fileversionIdAndNodeOffsetIdMap,
  estimateData,
  viewerState,
  workflowData,
  collideeData,
  colliderData,
  isFileAnOlderVersion,
  additionalCostTotal,
  estimateFieldsNeedToRefresh,
  Databank,
  breadCrumbData,
  workflowResourceType,
  reviewData,
  fileSyncState,
  fileUploadList,
  fileUploadState,
  contractDetails,
  currencyDetails,
  isFileVersionUploading,
  currentSelectedSidebarCell,
  showStickySidebar,
  currentPath,
  browserPopup: browserPopupReducer,
  resourceCreated,
  modelProperties,
});
